<template>
  <div class="max-vertical-height">
    <vs-prompt
      :title="activeAd.name"
      color="primary"
      accept-text="Build"
      type="alert"
      @accept="redirectToAd"
      @cancel="clearActiveAd"
      :active.sync="redirectToAdPromptActive">
      <div class="con-exemple-prompt" style="padding: 5px;">
        <h6>Description</h6>
        <p>
          {{ activeAd.description }}
        </p>
        <strong>${{ Number(activeAd.price).toFixed(2) }}</strong>
      </div>
    </vs-prompt>
    <breadcrumbs :title="offersKidsPages ? 'Kids Pages' : 'Ads'"
      :crumbs="[
        { name: store.name, route: 'Store', params: { link: store.link } },
        { name: offersKidsPages ? 'Kids Pages' : 'Ads', route: 'Ads', params: { link: store.link } }]">
    </breadcrumbs>
    <div class="main_content">
      <div class="section small_pt pb_20 page-section">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-6">
              <div class="heading_s3 text-center current-year-header">
                <h2>Available {{ offersKidsPages ? 'Kids Pages' : 'Yearbook Ads' }}</h2>
              </div>
              <div class="small_divider clearfix"></div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12" v-if="dedicationAdProducts.length > 0">
              <carousel :perPageCustom="[[0, 1], [481, 2], [768, 3], [1199, 4]]"
                :scrollPerPage="true"
                :navigationEnabled="true"
                :paginationEnabled="false"
                navigationNextLabel="&rsaquo;"
                navigationPrevLabel="&lsaquo;">
                <!-- INCLUDE SLIDE THAT WHEN CLICKED CONTINUES WORK IN PROGRESS -->
                <slide>
                  <product-item :link="store.link" :product="{}" :continueAdWorkInProgress="true" @continueAdWorkInProgress="redirectToContinueWork"  />
                </slide>
                <!-- CREATE SLIDE FOR EACH DEDICATION AD -->
                <slide v-for="(product, i) in dedicationAdProducts" :index="i" :key="product.id">
                  <product-item :link="store.link" :product="product" :canBuildAdAds="true" @createAd="redirectToAdPrompt" />
                </slide>
              </carousel>
            </div>
            <div v-else class="col-md-12 none-available">
              None Available
            </div>
          </div>
        </div>
      </div>
      <div class="section small_pt pb_20 prior-year-section" >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-6">
              <div class="heading_s3 text-center prior-year-header">
                <h2>Completed {{ offersKidsPages ? 'Kids Pages' : 'Yearbook Ads' }}</h2>
              </div>
              <div class="small_divider clearfix"></div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12" v-if="userAds.length > 0">
              <carousel :perPageCustom="[[0, 1], [481, 2], [768, 3], [1199, 4]]"
                :scrollPerPage="true"
                :navigationEnabled="true"
                :paginationEnabled="false"
                navigationNextLabel="&rsaquo;"
                navigationPrevLabel="&lsaquo;">
                <slide v-for="(ad, i) in userAds" :index="i" :key="ad.id">
                  <product-item :link="store.link" :ad="ad" :product="ad.product" @deleteExistingAd="deleteDedicationAd" />
                </slide>
              </carousel>
            </div>
            <div v-else class="col-md-12 none-available">
              None Available
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.vuesax-app-is-ltr .con-vs-dialog .vs-dialog footer button {
  margin: 5px !important;
}
.vuesax-app-is-ltr .con-vs-dialog .vs-dialog header .vs-dialog-cancel {
  display: none !important;
}
.con-vs-dialog .vs-dialog {
  min-width: 450px;
}
</style>

<script>
import to                    from '@/utils/to'
import axios                 from 'axios'
import { mapGetters }        from 'vuex'
import { Carousel, Slide }   from 'vue-carousel'
import { storeCommonMixin }  from '@/mixins/storeCommon'
import ProductItem           from '@/components/pages/partials/ProductItem'
import Breadcrumbs           from '@/components/layout/header/Breadcrumbs'
import Repository            from '@/repositories/RepositoryFactory'
const DedicationAdRepository = Repository.get('dedication_ads')
const $ = window.$

const PIXAMI_BASE_URI = 'https://bookbuilder.pixami.com'
const PIXAMI_CREATE_ACCOUNT_URI = `${PIXAMI_BASE_URI}/AdCreation/cart2/account/newaccount_ws.php?a=create`
const PIXAMI_LOGIN_URL = `${PIXAMI_BASE_URI}/AdCreation/cart2/login_ws.php`
const PIXAMI_CREATE_PRODUCT_URI = '../print3/PrintThemeSelect.jsp'
const PIXAMI_CONTINUE_URI = '../home3/ExistingProduct.jsp'
// const PIXAMI_EDIT_EXISTING_AD = '../print3/EditPrint.jsp'
const AD_PRODUCT_CODES = [0, 51303, 51302, 51301, 51300]
const CORS_BYPASS_URI = 'https://studiosource-cors-proxy.herokuapp.com/'

export default {
  name: 'Ads',
  mixins: [storeCommonMixin],
  components: { Breadcrumbs, Carousel, Slide, ProductItem },
  data () {
    return {
      userAds: [],
      activeAd: {},
      redirectToAdPromptActive: false,
    }
  },
  computed: {
    ...mapGetters([
      'dedicationAdProducts',
      'dedicationUser',
      'offersKidsPages',
      'store'
    ])
  },
  methods: {
    /**
     * Activates ad prompt before redirect
     * @param product - Dedication ad product
     */
    redirectToAdPrompt: function (product) {
      this.activeAd = product
      this.redirectToAdPromptActive = true
    },
    /**
     * Clears active ad object
     */
    clearActiveAd: function () {
      this.activeAd = {}
      this.redirectToAdPromptActive = false
    },
    /**
     * Redirects user to create an ad
     * @param product - Dedication ad product
     */
    redirectToAd: async function () {
      let productCode = AD_PRODUCT_CODES[this.activeAd.typeDedicationAd]
      if (productCode !== 0) {
        $('#product-image-'+this.activeAd.id).hide()
        $('#ad-image-container-'+this.activeAd.id).show()
        let title = `${this.activeAd.name} ${(new Date()).toLocaleString()}`
        const { data } = await axios.post(CORS_BYPASS_URI, this.buildCreateUpdateData(), { headers: { 'Target-URL': PIXAMI_CREATE_ACCOUNT_URI }})
        let formatedResponse = data.substring(24) // Remove header
        let responseXml = $($.parseXML(formatedResponse))
        let uid = responseXml.find('uid').text()
        if (!isNaN(uid) && Number(uid) > 0) {
          let productData = `?ProductID=${productCode}&Type=Ads&PaperType=Image&Title=${title}`
          let redirectUrl = encodeURIComponent(encodeURIComponent(PIXAMI_CREATE_PRODUCT_URI + productData))
          const finalRedirect = PIXAMI_LOGIN_URL + this.buildLoginData() + '&url=' + redirectUrl
          window.location.href = finalRedirect // GET request
        }
      } else {
        throw new Error('No matching dedication ad type')
      }
    },
    /**
     * Redirects user to continue work
     */
    redirectToContinueWork: async function () {
      $('#work-in-progress-icon').hide()
      $('#ad-continue-work-in-progress').show()
      const { data } = await axios.post(CORS_BYPASS_URI, this.buildCreateUpdateData(), { headers: { 'Target-URL': PIXAMI_CREATE_ACCOUNT_URI }})
      let formatedResponse = data.substring(24) // Remove header
      let responseXml = $($.parseXML(formatedResponse))
      let uid = responseXml.find('uid').text()
      if (!isNaN(uid) && Number(uid) > 0) {
        const redirect = PIXAMI_LOGIN_URL + this.buildLoginData() + '&url=' + PIXAMI_CONTINUE_URI
        window.location.href = redirect // GET request
      }
    },
    /**
     * Builds create / update Pixami user data from pixamiUser
     */
    buildCreateUpdateData () {
      return `user_email=${this.dedicationUser.email}&` +
        `username=${this.dedicationUser.email}&` +
        `first_name=${this.dedicationUser.email}&` +
        `last_name=${this.dedicationUser.email}&` +
        `pwd=${this.dedicationUser.password}`
    },
    /**
     * Builds login data
     */
    buildLoginData () {
      return `?uid=${this.dedicationUser.email}` +
        `&pwd2=${this.dedicationUser.password}&a=login`
    },
    /**
     * Gets the users list of dedication ads
     * @param userId - Dedication ad user ID
     */
    getDedicationAds: async function (userId) {
      let err, ads;
      [err, ads] = await to(DedicationAdRepository.get({ dedicationAdUserId: userId, storeId: this.store.id, include: ['product'], order: 'id DESC' }))
      if (!ads) throw new Error(err)
      // Add only current year ads
      for (let i = 0; i < ads.data.length; i++) {
        const ad = ads.data[i]
        if (ad && ad.product.year === 2025) {
          this.userAds.push(ad)
        }
      }
    },
    /**
     * Deletes a dedication ad from a user's inventory
     * @param id - Dedication ad ID
     */
    deleteDedicationAd: async function (id) {
      $('#ad-image-'+id).hide()
      $('#ad-image-container-'+id).show()
      let err, ad;
      [err, ad] = await to(DedicationAdRepository.delete(id))
      if (!ad) throw new Error(err)
      this.getDedicationAds(this.dedicationUser.id)
    }
  },
  mounted () {
    if (!this.dedicationUser.email) this.$router.push({ name: 'AdLogin' })
    this.getDedicationAds(this.dedicationUser.id)
  }
}
</script>
