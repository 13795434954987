<template>
  <ul class="navbar-nav">
  <li><router-link class="nav-link nav_item" :to="{ name: 'Store', params: { link: store.link } }">Store</router-link></li>
  <li v-if="bAds"><router-link class="nav-link nav_item" :to="{ name: 'Ads', params: { link: store.link } }">{{ offersKidsPages ? 'Kids Pages' : 'Ads' }}</router-link></li>
  <!-- <li><router-link class="nav-link nav_item" :to="{ name: 'FAQ', params: { link: store.link } }">FAQ</router-link></li> -->
  <!-- <li><router-link class="nav-link nav_item" :to="{ name: 'ContactUs', params: { link: store.link } }">Contact Us</router-link></li> -->
  <li><router-link class="nav-link nav_item" :to="{ name: 'TermsAndConditions', params: { link: store.link } }">Terms and Conditions</router-link></li>
  <li><a class="nav-link nav_item" href="https://yearbookmarket.helpscoutdocs.com/" target="_blank" rel="noopener noreferrer">Support FAQ</a></li>
  <li><router-link class="nav-link nav_item" :to="{ name: 'OrderLookup', params: { link: store.link } }">Did I already order?</router-link></li>
  <li><router-link class="nav-link nav_item" :to="{ name: 'Cart', params: { link: store.link } }">Cart</router-link></li>
  <li><router-link class="nav-link nav_item" :to="{ name: 'Checkout', params: { link: store.link } }">Checkout</router-link></li>
</ul>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'HeaderLinks',
  props: {
    bAds: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'store',
      'offersKidsPages'
    ])
  }
}
</script>
