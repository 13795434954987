<template>
  <div>
    <div class="item product-item" v-if="continueAdWorkInProgress">
      <div class="product_box text-center">
        <div class="product_info product-info-container" style="height: 50px; background: #fff">
          <div class="product_price">
            <span class="price">WORK IN PROGRESS</span>
          </div>
        </div>
        <div class="product_img product-img-container">
          <i id="work-in-progress-icon"
            class="icon-refresh product-logo-image"
            style="font-size: 125px; margin-top: 10px;" />
          <div id="ad-continue-work-in-progress" class="product-logo-image" style="display: none;">
            <div class="lds-ellipsis">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
        <div class="product_info product-info-container">
          <div class="product_price">
            <span class="price"><br></span>
          </div>
          <h6 class="product_title">CONTINUE WORK IN PROGRESS</h6>
          <p style="deadline"><strong style="margin-right: 5px;">CLICK HERE</strong></p>
          <div class="add-to-cart">
            <button class="btn btn-fill-out btn-radius" @click="continueWorkInProcessClicked">
              <i class="icon-arrow-right"></i> Continue
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="item product-item" v-else-if="canBuildAdStore">
      <div class="product_box text-center">
        <div class="product_info product-info-container" style="height: 50px; background: #fff">
          <div class="product_price">
            <span class="price">{{ offersKidsPages ? 'CREATE A KIDS PAGE' : 'CREATE A YEARBOOK AD' }}</span>
          </div>
        </div>
        <div class="product_img product-img-container">
          <img src="/assets/images/build-a-yearbook-ad.png" class="product-logo-image">
        </div>
        <div class="product_info product-info-container">
          <div class="product_price">
            <span class="price"><br></span>
          </div>
          <h6 class="product_title">{{ offersKidsPages ? 'CREATE A KIDS PAGE' : 'CREATE A YEARBOOK AD' }}</h6>
          <p class="deadline">
            <!-- <strong style="margin-right: 5px;">DEADLINE:</strong>VARIES -->
          </p>
          <div class="add-to-cart">
            <router-link :to="{ name: 'Ads', params: { link: link }}"
              class="btn btn-fill-out btn-radius">
              <i class="icon-arrow-right"></i> Create
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="item product-item" v-else-if="product.typeProduct === 6">
      <div class="product_box text-center">
        <div class="product_info product-info-container" style="height: 50px; background: #fff">
          <div class="product_price">
            <span class="price">PHOTO UPLOAD LINK</span>
          </div>
        </div>
        <div class="product_img product-img-container">
          <img :src="productImageUri(product)" class="product-logo-image">
        </div>
        <div class="product_info product-info-container">
          <div class="product_price">
            <span class="price"><br></span>
          </div>
          <h6 class="product_title">SUBMIT YEARBOOK PHOTOS<br> FOR CONSIDERATION</h6>
          <p class="deadline">
            <strong style="margin-right: 5px;">DEADLINE:</strong>{{ product.deadline }}
          </p>
          <div class="add-to-cart">
            <a :href="product.description" target="_blank" class="btn btn-fill-out btn-radius">
              <i class="icon-arrow-right"></i> Submit
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="item product-item" v-else>
      <div class="product_box text-center">
        <div class="product_info product-info-container" style="height: 50px; background: #fff">
          <div class="product_price">
            <span class="price" v-if="ad.id" v-html="adCreatedAtDate(ad.createdAt)"></span>
            <span class="price" v-else>
              {{ product.year > 0 ? `${product.year - 1} - ${product.year}` :
                `${(new Date()).getFullYear()} - ${Number((new Date()).getFullYear()) + 1}` }}
            </span>
          </div>
        </div>
        <div class="product_img product-img-container">
          <div :id="'ad-image-container-'+(product.id || 'new')" class="product-logo-image" style="display: none;">
            <div class="lds-ellipsis">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <img v-if="ad.id" :src="ad.uriPreview" :alt="ad.title" class="product-logo-image" :id="'ad-image-'+ad.id">
          <img v-else :src="productImageUri(product)" :alt="product.name.substring(0, 31)" class="product-logo-image" :id="'product-image-'+product.id">
        </div>
        <div class="product_info product-info-container">
          <div class="product_price">
            <span class="price" v-if="ad.id">${{ Number(ad.productPrice).toFixed(2) }}</span>
            <span class="price" v-else>${{ product.price.toFixed(2) }}</span>
          </div>
          <h6 class="product_title">{{productTitle}}</h6>
          <div class="pr_desc">{{ product.description }}</div>
          <p :class="product.lastEndDate === product.deadline ? '' : 'deadline'" v-if="!ad.id" :style="product.lastEndDate === product.deadline ? 'margin-top: 15px;' : 'margin-top: -3px;'">
            <span v-if="product.lastEndDate === product.deadline"><strong style="margin-right: 5px;">DEADLINE:</strong>{{ product.deadline }}</span>
            <template v-else>
              <table style="margin: 0 auto;">
                <tr>
                  <td style="text-align: right;"><strong style="margin-right: 6px;">SALE PRICE ENDS:</strong></td>
                  <td style="text-align: right;"><span>{{ product.deadline }}</span></td>
                </tr>
                <tr>
                  <td style="text-align: right;"><strong style="margin-right: 6px;">FINAL DEADLINE:</strong></td>
                  <td style="text-align: right;"><span>{{ product.lastEndDate }}</span></td>
                </tr>
              </table>
            </template>
          </p>
          <div class="add-to-cart">
            <button v-if="canBuildAdAds" class="btn btn-fill-out btn-radius" @click="createAdClicked">
              <i class="icon-arrow-right"></i> New
            </button>
            <template v-else-if="ad.id">
              <button  class="btn btn-fill-out btn-radius" style="padding: 10px 15px;" @click="deleteExistingAdClicked">
                <i class="icon-trash"></i> Delete
              </button>
              <router-link :to="{ name: 'Ad', params: { link: link, id: ad.id }}"
                class="btn btn-fill-out btn-radius" style="padding: 10px 15px;">
                <i class="icon-arrow-right"></i> Order
              </router-link>
            </template>
            <router-link v-else :to="{ name: 'Product', params: { link: link, id: product.id }}"
              class="btn btn-fill-out btn-radius">
              <i class="icon-arrow-right"></i> Order
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.product-logo-image {
  height: 150px;
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.product-img-container {
  height: 200px;
  background-color: #eee !important;
}
.product-info-container {
  height: 125px;
}
.deadline {
  font-size: 14px;
}
.deadline strong {
  margin-right: 5px;
}
</style>

<script>
import { mapGetters } from 'vuex'
import { storeCommonMixin } from '@/mixins/storeCommon'

export default {
  name: 'ProductItem',
  mixins: [storeCommonMixin],
  props: {
    link: {
      type: String,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    ad: {
      type: Object,
      default: function () { return {} }
    },
    canBuildAdStore: { // Is this an ad that can be built accessed from store page
      type: Boolean,
      default: false
    },
    canBuildAdAds: { // Is this an ad that can be built accessed from ads page
      type: Boolean,
      default: false
    },
    continueAdWorkInProgress: { // Is this an item that references continue ad work in progress
      type: Boolean,
      default: false
    },
    offersKidsPages: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'account'
    ]),
    productTitle () {
      let name = this.ad.id ? this.ad.title : this.product.name
      if (name.length > 31) {
        name = name.substring(0, 28) + '...'
      }
      return name
    }
  },
  methods: {
    createAdClicked () {
      this.$emit('createAd', this.product)
    },
    continueWorkInProcessClicked () {
      this.$emit('continueAdWorkInProgress', this.product.typeDedicationAd)
    },
    deleteExistingAdClicked () {
      this.$emit('deleteExistingAd', this.ad.id)
    },
    adCreatedAtDate (createdAt) {
      return 'CREATED: ' + this.$options.filters.filterDate(new Date(createdAt), this.account.timezone)
    }
  }
}
</script>
